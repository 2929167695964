import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import "@fontsource/roboto";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";

import { Link, useParams } from "react-router-dom";
import { userActions } from "../../../../store/user-store/user-slice";
import { SingIn } from "../../../../store/user-store/user-actions";
import { uiActions } from "../../../../store/ui-slice";

import "./login.css";

import { getImage, getTokenInfo } from "../../../../utils/Guard";

const Login = () => {
  const isValid = useSelector((state) => state.user.userValidation);
  const user = useSelector((state) => state.user.user);
  const userInfo = getTokenInfo();
  const notification = useSelector((state) => state.ui.notification);
  const dispatch = useDispatch();
  const params = useParams();


  useEffect(() => {
    if (userInfo !== null && userInfo !== "") {
      window.location.href = "/home/" + params.conferenceCode;
    }
  }, [dispatch, params.conferenceCode, userInfo]);

  const onChangeHandler = (id, value) => {
    dispatch(userActions.modifyPropertyValue({ id, value }));
    if (value !== "") {
      dispatch(userActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(userActions.setFieldValid({ id, value: false }));
    }
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (user.email === "" && user.password === "") {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      dispatch(SingIn(user.email, user.password, params.conferenceCode));
    }
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };

  const header = (
    <img
      alt="Card"
      className="loginImage"
      src={getImage(params.conferenceCode)}
    />
  );

  return (
    <>
      <div className="card flex justify-content-center loginBody">
        <div className="formgrid grid ">
          <div className="field col-12 md:col-12">
            <Card className="loginCard">
              <div className="formgrid grid ">
                <div className="field col-12 md:col-12">{header}</div>
                <div className="field col-12 md:col-3"></div>
                <div className="field col-12 md:col-12 loginTitle">
                  <span className="loginTitleText">Iniciar Sesión</span>
                  <br />
                  <br />
                  <span className="loginTitleNewUser">
                    ¿Nuevo en la plataforma? &nbsp;
                    <Link
                      to={`/register/${params.conferenceCode}`}
                      style={{ textDecoration: "none", color: "#2755A9" }}
                    >
                      <span>Crear una Cuenta</span>
                    </Link>
                  </span>
                </div>
                <div className="field col-12 md:col-12"></div>
                <form onSubmit={onSubmitHandler}>
                  <div className="formgrid grid">
                    <div className="field col-12 md:col-12">
                      <InputText
                        className="loginEmailTextBox"
                        id="email"
                        value={user.email}
                        onChange={(e) =>
                          onChangeHandler("email", e.target.value)
                        }
                        placeholder="Email"
                      />
                      <div>{getFormErrorMessage("email")}</div>
                    </div>
                    <div
                      className="field col-12 md:col-12"
                      style={{ marginBottom: "0px" }}
                    >
                      <InputText
                        className="passwordTextBox"
                        id={user.password}
                        value={user.password}
                        type="password"
                        onChange={(e) =>
                          onChangeHandler("password", e.target.value)
                        }
                        placeholder="Contraseña"
                      />
                      <Link
                        className="forgotPassword"
                        to={`/forgotpassword/${params.conferenceCode}`}
                      >
                        <small>¿Olvidaste tu contraseña?</small>
                      </Link>
                      <div>{getFormErrorMessage("password")}</div>
                    </div>
                  </div>
                  <Button
                    label="Iniciar sesión"
                    className="loginButton"
                    icon={
                      notification && notification.status === "pending"
                        ? "pi pi-spin pi-spinner"
                        : ""
                    }
                    disabled={notification && notification.status === "pending"}
                  />
                </form>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
