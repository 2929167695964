import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeContent from "../../../components/HomeContent";
import { IsValidInfo, getTokenInfo, validateToken } from "../../../utils/Guard";
import { userActions } from "../../../store/user-store/user-slice";
import { GetOrdensById } from "../../../store/orden-store/orden-actions";

const Home = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const userInfo = getTokenInfo();
  const orden = useSelector((state) => state.orden.orden);

  useEffect(() => {
    if (!validateToken()) {
      window.location.href = "/conferences";
    }
  }, [dispatch, params.conferenceCode]);

  useEffect(() => {
    if (
      orden &&
      (orden.id === undefined || orden.id === 0) &&
      userInfo !== null &&
      userInfo.OrdenId !== undefined &&
      userInfo.OrdenId !== null &&
      userInfo.OrdenId > 0
    ) {
      dispatch(GetOrdensById(userInfo.OrdenId, userInfo.Email));
    }
  }, [dispatch, orden, userInfo.Email, userInfo.OrdenId]);

  useEffect(() => {
    if (
      userInfo !== null &&
      userInfo.OrdenId !== undefined &&
      userInfo.OrdenId !== null &&
      userInfo.OrdenId > 0
    ) {
      window.location.href = "/orden/" + params.conferenceCode;
    }
  }, [dispatch, params.conferenceCode, userInfo]);

  if (!IsValidInfo()) {
    dispatch(
      userActions.setuser({
        user: {
          id: userInfo.UserId,
          name: userInfo.Name,
          lastName: userInfo.LastName,
          phone: userInfo.Phone,
          country: userInfo.Country,
          company: {name: userInfo.Business},
          charge: { name: userInfo.Charge },
          department: { name: userInfo.Department },
        },
      })
    );
  }

  return (
    <>
      <HomeContent />
    </>
  );
};
export default Home;
