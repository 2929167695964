import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "./YesNoModal.css";

const YesNoModal = (props) => {
  const footerContent = (
    <div style={{height:"max-content",padding:"0rem", alignSelf:"center", marginTop: "2.3rem"}}>  
      <Button
        label="Cancelar"
        icon="pi pi-times"
        rounded
        outlined
        severity="danger"
        onClick={() => props.setVisible(false)}
        style={{
          height: "43px",
          borderColor: "#19366C",
          backgroundColor: "#D1432F",
          color: "#ffffff",
          fontFamily: "'Roboto'",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "18.75px",
          marginTop: "-10px",
          border: "none"
        }}
      />
      {props.showButton && (
        <Button
          label={props.yesButtonLabel ? props.yesButtonLabel : "Si"}
          rounded
          outlined
          severity="success"
          icon={props.icon ? props.icon : "pi pi-check"}
          onClick={() => {
            props.yesHandler(true);
          }}
          style={{
            height: "43px",
            borderColor: "#19366C",
            backgroundColor: "#19366C",
            color: "#ffffff",
            fontFamily: "'Roboto'",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "18.75px",
            marginTop: "-10px",
          }}
        />
      )}
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={"Cambio de contraseña"}
        visible={props.visible}
        // style={{ width: "100%" }}
        onHide={() => props.setVisible(false)}
        footer={footerContent}
        className="warningDialog"
      >
        {props.content}
      </Dialog>
    </div>
  );
};
export default YesNoModal;
