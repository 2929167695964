import React, { useEffect, useState } from 'react';
import './ConferenceSelector.css';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllConferences } from '../store/conferences-store/conference-actions';
import logoAba from '../assets/img/ABAColor.png';
import Loading from "../components/UI/Loading";
import { is } from 'express/lib/request';

const ConferenceSelector = () => {
    const conferences = useSelector((state) => state.conference.conference);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (conferences === undefined || conferences === null || conferences.id === 0) {
            dispatch(GetAllConferences());
            setIsLoading(false);
        }
    }, [conferences]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="homepage">
            <img src={logoAba} alt="Logo" className="congress-logo" />
            <h1>
                Selecciona un congreso
            </h1>
            <div className="grid-container">
                {conferences.length > 0 && conferences.map((conference, index) => (
                    <div key={index} onClick={() => window.location.href = "/login/" + conference.conferecenCodeName} className="grid-item">
                        <img src={conference.imageUrl} alt={conference.name} className="congress-image" />
                        <p className='conferenceName'>{conference.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ConferenceSelector;