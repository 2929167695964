import { chargeActions } from "./charge-slice";

export const GetCharges = (email) => {
  return async (dispatch) => {
    const GetChargesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/charge/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetChargesInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          chargeActions.setCharges({
            charges: response.data
              .filter((charge) => charge.status === true)
              .map((charge) => {
                return {
                  code: charge.id,
                  name: charge.name,
                };
              }),
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          chargeActions.setCharges({
            statues: [],
          })
        );
      }
    } catch (error) {}
  };
};
