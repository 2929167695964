import React from "react";
import "./App.css";
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import BaseLayout from "./layouts/BaseLayout";
import ErrorPage from "./pages/ErrorPage";

//Authentications
import Login from "./pages/Regular/User/Authentication/Login";
import Register from "./pages/Regular/User/Authentication/Register";
import EmailConfirmation from "./pages/Regular/User/Authentication/EmailConfirmation";
import ForgotPassword from "./pages/Regular/User/Authentication/ForgotPassword";

import Home from "./pages/Regular/User/Home";

import ChangePassword from "./pages/Regular/User/Authentication/ChangePassword";
import OrdenDetail from "./pages/Regular/Ordens/OrdenDetail";
import OrdenPaymentInfo from "./pages/Regular/Ordens/OrdenPaymentInfo";
import OrdenPayTC from "./pages/Regular/Ordens/OrdenPayTC";
import OrdenApprove from "./pages/Regular/Ordens/OrdenApprove";
import OrdenDeclined from "./pages/Regular/Ordens/OrdenDeclined";
import OrdenTransfer from "./pages/Regular/Ordens/OrdenTransfer";
import ConferenceSelector from "./pages/ConferenceSelector";
import TransferenceRequested from "./pages/Regular/Ordens/TransferenceRequested";

function App() {
  const routeDefinitions = createRoutesFromElements(
    <Route>
      <Route path="*" element={<ErrorPage />} />

      <Route
        path="/"
        element={<BaseLayout />}
        children={[
          <Route
          key="conferences"
          path="/conferences"
          element={<ConferenceSelector />}
          />,
          <Route
            key="Login"
            path="/login/:conferenceCode"
            element={<Login />}
          />,
          <Route
            key="Register"
            path="/register/:conferenceCode"
            element={<Register />}
          />,
          <Route
            key="EmailConfirmation"
            path="/emailconfirmation/:conferenceCode/:userId/:token"
            element={<EmailConfirmation />}
          />,
          <Route
            key="ForgotPassword"
            path="/forgotpassword/:conferenceCode"
            element={<ForgotPassword />}
          />,

          <Route
            key="ChangePassword"
            path="/ChangePassword/:conferenceCode/:userId/:token"
            element={<ChangePassword />}
          />,
          <Route key="Home" path="/home/:conferenceCode" element={<Home />} />,
          <Route
            key="OrdenDetail"
            path="/orden/:conferenceCode"
            element={<OrdenDetail />}
          />,
          <Route
            key="OrdenPaymentInfo"
            path="/orden/paymentInfo/:conferenceCode"
            element={<OrdenPaymentInfo />}
          />,
          <Route
            key="OrdenPayTC"
            path="/orden/paytc/:conferenceCode"
            element={<OrdenPayTC />}
          />,
          <Route
            key="OrdenApprove"
            path="/orden/Pay/Approved/:conferenceCode"
            element={<OrdenApprove />}
          />,
          <Route
            key="OrdenDeclined"
            path="/orden/Pay/Declined/:conferenceCode"
            element={<OrdenDeclined />}
          />,
          <Route
            key="OrdenTransfer"
            path="/orden/paytf/:conferenceCode"
            element={<OrdenTransfer />}
          />,
          <Route
            key="TransferenceRequested"
            path="/orden/transferenceRequested"
            element={<TransferenceRequested />}
          />,
        ]}
      />
    </Route>
  );
  const router = createBrowserRouter(routeDefinitions);

  return <RouterProvider router={router} />;
}
export default App;
