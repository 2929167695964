import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTokenInfo } from "../../../utils/Guard";
import { useEffect, useState } from "react";
import {
  AddUser,
  GetOrdensById,
  RemoveUser,
} from "../../../store/orden-store/orden-actions";
import { GetConferenceByCode } from "../../../store/conferences-store/conference-actions";
import DialogModal from "../../../components/DialogDialogModal";
import { userActions } from "../../../store/user-store/user-slice";
import "./OrdenDetail.css";
import { uiActions } from "../../../store/ui-slice";
import { CleanUser } from "../../../store/user-store/user-actions";
import Loading from "../../../components/UI/Loading";
import { Dialog } from "primereact/dialog";

const OrdenDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orden = useSelector((state) => state.orden.orden);
  const conference = useSelector((state) => state.conference.conference);
  const user = useSelector((state) => state.user.user);
  const notification = useSelector((state) => state.ui.notification);

  const userInfo = getTokenInfo();
  const [priceWithDiscount, setPriceWithDiscount] = useState(0);
  const [visibleUser, setVisibleUser] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [totalPay, setTotalPay] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [visible, setVisible] = useState(false);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    if (
      orden &&
      (orden.id === undefined || orden.id === 0) &&
      userInfo.OrdenId > 0
    ) {
      dispatch(GetOrdensById(userInfo.OrdenId, userInfo.Email));
      setLoading(false);
    }
  }, [dispatch, orden, userInfo.Email, userInfo.OrdenId]);

  useEffect(() => {
    if (conference && conference.id === 0) {
      dispatch(GetConferenceByCode(params.conferenceCode));
    }
  }, [conference, dispatch, params.conferenceCode]);

  useEffect(() => {
    if (orden && orden.discount && orden.discount.id > 0) {
      let userCount = 0;
      orden.users.forEach((user) => {
        if (user.id !== 0) {
          userCount++;
        }
      });

      if (!orden.discount.grupal) {
        setShowDiscount(true);
        if (orden.discount.percentage) {
          const discount =
            conference.price * (orden.discount.discountPercentage / 100);
          setPriceWithDiscount(conference.price - discount);
        } else {
          setPriceWithDiscount(
            conference.price - orden.discount.discountNominal
          );
        }
      } else if (orden.discount.grupal && userCount >= 3) {
        setShowDiscount(true);
        if (orden.discount.percentage) {
          const discount =
            conference.price * (orden.discount.discountPercentage / 100);
          setPriceWithDiscount(conference.price - discount);
        } else {
          setPriceWithDiscount(
            conference.price - orden.discount.discountNominal
          );
        }
      }
    }
  }, [conference.price, orden]);

  useEffect(() => {
    if (
      (orden.ordenStatusId === 2 || orden.ordenStatusId === 10) &&
      !isComplete
    ) {
      setIsComplete(true);
    }
  }, [orden, isComplete]);

  useEffect(() => {
    if (isComplete) {
      localStorage.setItem("token", null);
      setTimeout(function () {
        window.location.href = "/login/" + params.conferenceCode;
      }, 5000);
    }
  });

  useEffect(() => {
    if (notification && notification.status === "success" && refresh === true) {
      setTimeout(function () {
        window.location.href = "/orden/" + params.conferenceCode;
      }, 4500);
    }
  }, [notification, params.conferenceCode, refresh]);

  useEffect(() => {
    if (
      orden &&
      orden.discount &&
      orden.discount.id > 0 &&
      orden.users !== undefined &&
      orden.users.length > 0
    ) {
      if (
        orden.ordenStatusId === 2 ||
        orden.ordenStatusId === 10 ||
        orden.ordenStatusId === 17 ||
        orden.ordenStatusId === 18
      ) {
        setUsers(
          orden.users.filter((user) => {
            return user.id > 0;
          })
        );
      } else {
        setUsers(orden.users);
      }

      let count = 0;

      orden.users.forEach((user) => {
        if (user.id !== 0) {
          count++;
        }
      });
      setTotalPay(
        count * (priceWithDiscount !== 0 ? priceWithDiscount : conference.price)
      );
    } else if (orden && orden.users !== undefined && orden.users.length > 0) {
      setUsers(orden.users);
      let count = 0;

      orden.users.forEach((user) => {
        if (user.id !== 0) {
          count++;
        }
      });
      setTotalPay(
        count * (priceWithDiscount !== 0 ? priceWithDiscount : conference.price)
      );
    }
  }, [conference.price, orden.users, priceWithDiscount]);

  useEffect(() => {
    if (window.innerWidth <= 430 && !isMobile) {
      setIsMobile(true);
    }
  }, [isMobile]);

  const addUserHandler = () => {
    if (users.find((x) => x.email === user.email) === undefined) {
      if (user !== undefined && (user.name === undefined || user.name === "")) {
        dispatch(userActions.setFieldValid({ id: "name", value: false }));
        return;
      }
      if (
        user !== undefined &&
        (user.lastName === undefined || user.lastName === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "lastName", value: false }));
        return;
      }
      if (
        user !== undefined &&
        (user.email === undefined || user.email === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "email", value: false }));
        return;
      }
      if (
        user !== undefined &&
        (user.cellPhone === undefined || user.cellPhone === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "cellPhone", value: false }));
        return;
      }
      if (
        user.country !== undefined &&
        (user.country === undefined || user.country === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "country", value: false }));
        return;
      }
      if (
        user.charge !== undefined &&
        (user.charge === undefined || user.charge === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "charge", value: false }));
        return;
      }
      if (
        user.department !== undefined &&
        (user.department === undefined || user.department === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "department", value: false }));
        return;
      }
      if (
        user.company !== undefined &&
        (user.company === undefined || user.company === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "company", value: false }));
        return;
      }

      const newUser = {
        id: user.id,
        email: user.email,
        name: user.name,
        lastName: user.lastName,
        cellPhone: user.cellPhone,
        country: user.country,
        address: user.address,
        company: {
          id: user.company.id,
          name: user.company.name,
        },
        charge: {
          id: user.charge.id,
          name: user.charge.name,
        },
        department: {
          id: user.department.id,
          name: user.department.name,
        },
      };

      dispatch(AddUser(userInfo.Email, newUser, userInfo.OrdenId));
      dispatch(userActions.restartState());
      setRefresh(true);
    } else {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "El participante ya se encuentra registrado en la orden.",
        })
      );
    }
  };

  const removeUserHandler = (id) => {
    dispatch(RemoveUser(userInfo.OrdenId, id, userInfo.Email));
    setLoading(true);
  };

  const validateAddParticipant = () => {
    let count = 0;
    users.forEach((user) => {
      if (user.id !== 0) {
        count++;
      }
    });
    if (count === users.length) {
      return true;
    }
    return false;
  };
  const addNewParticipant = () => {
    const user = {
      email: "",
      password: "",
      repassword: "",
      name: "",
      lastName: "",
      identification: "",
      cellPhone: "",
      country: "",
      city: "",
      address: "",
      company: {
        name: "",
      },
      charge: {
        name: "",
      },
      department: {
        name: "",
      },
      status: false,
      token: "",
      fullName: "",
      ordens: [],
    };
    const us = [...users];
    us.push(user);
    setUsers(us);
  };
  const validateTotalParticipants = () => {
    let count = 0;
    users.forEach((user) => {
      if (user.id !== 0) {
        count++;
      }
    });
    return orden.quantityOfUsers <= count;
  };

  const headerElement = (
    <span style={{ color: "#19366C" }} className="ordenDetailShowModalFont">
      No. Orden: {orden.id}
    </span>
  );

  const footerContent = (
    <div className="formgrid grid">
      {!isMobile && (
        <div className="field col-12 md:col-12 mt-4">
          <Button
            text
            raised
            type="button"
            label="Completar el resto de participantes"
            style={{ width: "100%" }}
            onClick={() => {
              setVisible(false);
            }}
            className="addParticipant"
          />
          <Button
            text
            raised
            type="button"
            visible={!isComplete}
            disabled={totalPay === 0}
            label="Continuar con el checkout"
            onClick={() => {
              navigate("/orden/paymentInfo/" + params.conferenceCode);
              setVisible(false);
            }}
            className="ordenDetailCheckoutButton"
          />
        </div>
      )}
      {isMobile && (
        <>
          <div className="field col-12 md:col-12 ">
            <Button
              text
              raised
              type="button"
              label="Completar el resto de participantes"
              style={{ width: "100%" }}
              onClick={() => {
                setVisible(false);
              }}
              className="completParticipant"
            />
          </div>
          <div className="field col-12 md:col-12 ">
            <Button
              text
              raised
              type="button"
              visible={!isComplete}
              disabled={totalPay === 0}
              label="Continuar con el checkout"
              onClick={() => {
                setVisible(false);
                navigate("/orden/paymentInfo/" + params.conferenceCode);
              }}
              className="ordenDetailCheckoutButton"
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <Dialog
        className={"warningDialog"}
        header="Advertencia"
        visible={isComplete}
      >
        <p>
          La orden ya fue completada, no se pueden agregar más participantes.
        </p>
      </Dialog>
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="ordenDetailShowModalBody"></p>
        <p className="ordenDetailShowModalBody">
          Se necesita la información completa de todos los participantes para
          procesar la orden, actualmente hay{" "}
          {users !== undefined &&
            users.length > 0 &&
            users.filter((user) => user.id > 0).length}{" "}
          participantes registrados y se seleccionaron {orden.quantityOfUsers}{" "}
          participantes.
        </p>
      </Dialog>
      <div className="ordenDetailHeader">
        <div className="ordenDetailTitle">
          <span>Orden: {orden.id}</span>
        </div>
        <br />
        <span style={{ color: "#ffffff", fontSize: "18px" }}>
          Por favor, completa la información del/de los participante(s).
        </span>
      </div>
      {isLoading === false && (
        <>
          <div className="grid">
            <div className="col-10 mt-6">
              <span className="participantsTitle">Participantes</span>
            </div>
            <div className="col-2 mt-6 ">
              <Button
                text
                raised
                type="button"
                label={isMobile ? "" : "Agregar Participante"}
                icon={isMobile ? "pi pi-user-plus" : ""}
                onClick={() => {
                  addNewParticipant();
                }}
                className="addParticipant"
              />
            </div>
          </div>
          <div>
            <div className="card col-12 flex">
              {users !== undefined && users.length > 0 && (
                <>
                  <div className="formgrid col-12 grid">
                    {users.map((user, index) => {
                      return (
                        <>
                          <div key={user.id} className="col-12">
                            <Card className="ordenDetailCard">
                              <div className="formgrid grid ">
                                {!isMobile && (
                                  <>
                                    {showDiscount && (
                                      <>
                                        <div className="field col-12 md:col-4">
                                          <div className="ordenDetailTextPriceWithDiscount">
                                            <span>
                                              {" "}
                                              USD${priceWithDiscount}
                                            </span>
                                          </div>
                                          <br />
                                          <div className="ordenDetailTextAmount">
                                            {user.id > 0 ? (
                                              <span>
                                                USD${conference.price}
                                              </span>
                                            ) : (
                                              <span>USD$0</span>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="field col-12 md:col-4"
                                          style={{ textAlign: "center" }}
                                        >
                                          <span className="ordenDetailWhosComming">
                                            {user.name !== ""
                                              ? user.name + " " + user.lastName
                                              : "¿Quién Asistirá?"}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                    {!showDiscount && (
                                      <>
                                        <div className="field col-12 md:col-4">
                                          <div className="ordenDetailTextPriceWithDiscount">
                                            {user.id > 0 ? (
                                              <span>
                                                USD${conference.price}
                                              </span>
                                            ) : (
                                              <span>USD$0</span>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="field col-12 md:col-4"
                                          style={{ textAlign: "center" }}
                                        >
                                          <span className="ordenDetailWhosComming">
                                            {user.name !== ""
                                              ? user.name + " " + user.lastName
                                              : "¿Quién Asistirá?"}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                    {index === 0 && user.email === "" && (
                                      <div className="field col-12 md:col-2">
                                        {user.email === "" && (
                                          <Button
                                            className="meButton"
                                            label="Yo"
                                            outlined
                                            visible={!isComplete}
                                            onClick={() => {
                                              dispatch(CleanUser());
                                              setUserEmail(userInfo.Email);
                                              setVisibleUser(true);
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}

                                    <div
                                      className={
                                        index === 0
                                          ? "field col-12 md:col-2 mobile"
                                          : "field col-12 md:col-2"
                                      }
                                    >
                                      {user.email === "" && (
                                        <Button
                                          className="delegateButton"
                                          label="Participante"
                                          outlined
                                          visible={!isComplete}
                                          onClick={() => {
                                            dispatch(CleanUser());
                                            setVisibleUser(true);
                                            setUserEmail("");
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className="field col-12 md:col-2">
                                      {user.email !== "" && (
                                        <Button
                                          className="modifyButton"
                                          label="Eliminar"
                                          visible={!isComplete}
                                          text
                                          onClick={() =>
                                            removeUserHandler(user.id)
                                          }
                                        />
                                      )}
                                    </div>
                                  </>
                                )}
                                {isMobile && (
                                  <>
                                    {showDiscount && (
                                      <>
                                        <div className="field col-12 md:col-4">
                                          <div className="ordenDetailTextPriceWithDiscount">
                                            <span>
                                              {" "}
                                              USD${priceWithDiscount}
                                            </span>
                                          </div>
                                          <br />
                                          <div className="ordenDetailTextAmount">
                                            {user.id > 0 ? (
                                              <span>
                                                USD${conference.price}
                                              </span>
                                            ) : (
                                              <span>USD$0</span>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="field col-12 md:col-4"
                                          style={{ textAlign: "center" }}
                                        >
                                          <span className="ordenDetailWhosComming">
                                            {user.name !== ""
                                              ? user.name + " " + user.lastName
                                              : "¿Quién Asistirá?"}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                    {!showDiscount && (
                                      <>
                                        <div
                                          className="field col-12 md:col-12"
                                          style={{
                                            textAlign: "center",
                                            marginLeft: "-30px",
                                          }}
                                        >
                                          <div className="ordenDetailTextPriceWithDiscount">
                                            {user.id > 0 ? (
                                              <span>
                                                USD${conference.price}
                                              </span>
                                            ) : (
                                              <span>USD$0</span>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="field col-12 md:col-12"
                                          style={{ textAlign: "center" }}
                                        >
                                          <span className="ordenDetailWhosComming">
                                            {user.name !== ""
                                              ? user.name + " " + user.lastName
                                              : "¿Quién Asistirá?"}
                                          </span>
                                        </div>
                                      </>
                                    )}
                                    {index === 0 && user.email === "" && (
                                      <div className="field col-12 md:col-12">
                                        {user.email === "" && (
                                          <Button
                                            className="meButton"
                                            label="Yo"
                                            outlined
                                            visible={!isComplete}
                                            onClick={() => {
                                              dispatch(CleanUser());
                                              setUserEmail(userInfo.Email);
                                              setVisibleUser(true);
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <div
                                      className={
                                        index === 0
                                          ? "field col-12 md:col-12 "
                                          : "field col-12 md:col-12"
                                      }
                                    >
                                      {user.email === "" && (
                                        <Button
                                          className="delegateButton"
                                          label="Participante"
                                          outlined
                                          visible={!isComplete}
                                          onClick={() => {
                                            dispatch(CleanUser());
                                            setVisibleUser(true);
                                            setUserEmail("");
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className="field col-12 md:col-2">
                                      {user.email !== "" && (
                                        <Button
                                          className="modifyButton"
                                          label="Eliminar"
                                          visible={!isComplete}
                                          text
                                          onClick={() =>
                                            removeUserHandler(user.id)
                                          }
                                        />
                                      )}
                                    </div>

                                    <hr
                                      style={{
                                        width: "100%",
                                        height: "70px",
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderBottom: "none",
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </Card>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </div>

            <Card className="ordenDetailResumeBox">
              <div className="formgrid grid">
                {isMobile && (
                  <div className="field col-12 md:col-12">
                    <p className="ordenDetailMessage">
                      Solo se procesarán las órdenes que tengan la información
                      del participante completa.
                    </p>
                  </div>
                )}
                {!isMobile && (
                  <div className="field col-12 md:col-3">
                    <p className="ordenDetailMessage">
                      Solo se procesarán las órdenes que tengan la información
                      del participante completa.
                    </p>
                  </div>
                )}
                {isMobile && (
                  <div className="field col-12 md:col-12">
                    <div className="ordenDetailTotal">
                      Total: USD{USDollar.format(totalPay)}
                    </div>
                  </div>
                )}
                {!isMobile && (
                  <div className="field col-12 md:col-6">
                    <div className="ordenDetailTotal">
                      Total: USD{USDollar.format(totalPay)}
                    </div>
                  </div>
                )}
                {isMobile && (
                  <div className="field col-12 md:col-12">
                    <Button
                      text
                      raised
                      type="button"
                      visible={!isComplete}
                      disabled={totalPay === 0}
                      label="Continuar con el checkout"
                      onClick={() => {
                        if (!validateTotalParticipants()) {
                          setVisible(true);
                        } else {
                          navigate(
                            "/orden/paymentInfo/" + params.conferenceCode
                          );
                        }
                      }}
                      className="ordenDetailCheckoutButton"
                    />
                  </div>
                )}
                {!isMobile && (
                  <div className="field col-12 md:col-3">
                    <Button
                      text
                      raised
                      type="button"
                      visible={!isComplete}
                      disabled={totalPay === 0}
                      label="Continuar con el checkout"
                      onClick={() => {
                        if (!validateTotalParticipants()) {
                          setVisible(true);
                        } else {
                          navigate(
                            "/orden/paymentInfo/" + params.conferenceCode
                          );
                        }
                      }}
                      className="ordenDetailCheckoutButton"
                    />
                  </div>
                )}
              </div>
            </Card>
          </div>
        </>
      )}
      {isLoading === true && <Loading />}
      <DialogModal
        email={userInfo.Email}
        header="Completa los detalles"
        visible={visibleUser}
        setVisible={setVisibleUser}
        addUserHandler={addUserHandler}
        refresh={refresh}
        searchEmail={userEmail}
      />
    </>
  );
};
export default OrdenDetail;
