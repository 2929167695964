import { ordenActions } from "./orden-slice";
import { uiActions } from "../ui-slice";

export const CreateOrder = (userName, orden) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Orden",
        message: "Se esta creando la orden",
      })
    );

    const createOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/orden/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Conference: {
              ConferecenCodeName: orden.conferecenCodeName,
            },
            DiscountCode: orden.discountCode,
            Amount: orden.amount,
            QuantityOfUsers: orden.quantityOfUsers,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear la orden");
      }
      const data = await response.json();
      console.log(data);

      return data;
    };
    try {
      const response = await createOrderInfo();

      if (response.result.errorCode === "OK") {
        localStorage.setItem("token", response.data.token);
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Orden Creada",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear la orden",
        })
      );
    }
  };
};
export const GetOrdensById = (OrdenId, UserName) => {
  console.log(OrdenId, UserName);

  return async (dispatch) => {
    const getOrdensInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/getOrdenById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: UserName,
            Id: OrdenId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getOrdensInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setOrden({
            orden: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener las ordenes",
        })
      );
    }
  };
};
export const AddUser = (userName, user, ordenId) => {
  return async (dispatch) => {
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/orden/addUsertoconferenceOrden`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            User: user,

          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setOrden({
            orden: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Participante Agregado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener las ordenes",
        })
      );
    }
  };
};
export const RemoveUser = (ordenId, userId, userName) => {
  return async (dispatch) => {
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/removeUser`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            User: {
              Id: userId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setOrden({
            orden: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener las ordenes",
        })
      );
    }
  };
};
export const UpdateOrden = (Orden, userName) => {
  return async (dispatch) => {
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/orden/updateOrden`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: Orden.ordenId,
            BillPhone: Orden.billPhone,
            BillName: Orden.billName,
            BillAddress: Orden.billAddress,
            BillEmail: Orden.billEmail,
            BillCountry: Orden.billCountry,
            PaymentMethodId: Orden.paymentMethodId,
            TernmConditions: Orden.ternmConditions,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setOrden({
            orden: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
export const DiscountOrden = (ordenId, userName, discountCode) => {
  return async (dispatch) => {
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/orden/discountOrden`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            DiscountCode: discountCode,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setOrden({
            orden: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
export const UpdatePayment = (ordenId, userName, transacion) => {
  return async (dispatch) => {
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/orden/updatePayment`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            Transaction: {
              ...transacion,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setTransaction({
            transaction: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
export const UploadFileOrder = (file, OrdenId, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Subiendo comprobante de pago",
        message: "Subiendo comprobante de pago al servidor.",
      })
    );
    const updateOrderInfo = async () => {
      let formData = new FormData();
      formData.append("UserName", userName);
      formData.append("Id", OrdenId);
      formData.append("File", file);

      const response = await fetch(
        `${process.env.REACT_APP_URL}conferences/orden/paymentReceipt`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrderInfo();

      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Comprobante de pago",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};

