import React, { useEffect } from "react";
import styles from "./TransferenceRequested.module.css";

import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Navigate, useParams } from "react-router-dom";
import { use } from "react";

const TransferenceRequested = () => {

    useEffect(() => {
        
        localStorage.setItem("token", null);
        setTimeout(function () {
          window.location.href = "/";
        }, 5000);
      
    });

    return (
        <div style={{marginTop:"13%", padding:"0% 10%", textAlign:"center"}}>
        <Card className={styles.cardMessage}>
        <div className={`pi pi-check-circle ${styles.checkCircleIcon}`}></div>
            <h1>Formulario enviado exitosamente</h1>
            <p >Gracias por su compra. Se le estará notificando a través del correo electrónico asociado a su cuenta una vez el proceso de pago esté completado</p>
            
        </Card>
        </div>
    );
    }

    export default TransferenceRequested;