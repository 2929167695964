import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Card } from "primereact/card";
import { ForgotPasswordAction } from "../../../../store/user-store/user-actions";
import { isValidEmail } from "../../../../utils/ValidateUserInfo";
import "./ForgotPassword.css";
import { getImage } from "../../../../utils/Guard";

let restartPage = false;

const ForgotPassword = () => {
  const notification = useSelector((state) => state.ui.notification);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const params = useParams();

  useEffect(() => {
    if (notification && notification.status === "success" && restartPage) {
      window.location.href = "/conferences";
    }
  }, [navigate, notification, params.conferenceCode]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (email === "" || !isValidEmail(email)) {
      setIsValid(false);
    } else {
      dispatch(ForgotPasswordAction(email, params.conferenceCode));
      restartPage = true;
    }
  };
  const getFormErrorMessage = (id) => {
    return !isValid ? (
      <Message severity="warn" text="Debe digitar un email" style={{}} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };
  const header = (
    <img
      alt="Card"
      className="forgotPasswordImage"
      src={getImage(params.conferenceCode)}
    />
  );

  return (
    <>
      <div className="card flex justify-content-center forgotPasswordBody">
        <div className="formgrid grid ">
          <div className="field col-12 md:col-12">
            <Card className="forgotPasswordCard">
              <div className="field col-12 md:col-12">{header}</div>
              <div className="field col-12 md:col-3"></div>
              <div className="field col-12 md:col-12 forgotPasswordTitle">
                <span className="forgotPasswordTitleText">
                  Recuperar contraseña
                </span>
              </div>
              <form onSubmit={onSubmitHandler}>
                <div className="formgrid grid">
                  <div className="field col-12 md:col-12">
                    <InputText
                      className="forgotPasswordEmailTextBox"
                      id="email"
                      value={email}
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div>{getFormErrorMessage("email")}</div>
                  </div>
                  <div className="field col-12 md:col-6">
                    <Button
                      label="Atrás"
                      className="forgotPasswordButtonBack"
                      onClick={() => {
                        navigate(`/login/${params.conferenceCode}`);
                      }}
                    />
                  </div>
                  <div className="field col-12 md:col-6 ">
                    <Button
                      label="Solicitar"
                      className="forgotPasswordButton"
                      icon={
                        notification && notification.status === "pending"
                          ? "pi pi-spin pi-spinner"
                          : ""
                      }
                      disabled={
                        notification && notification.status === "pending"
                      }
                    />
                  </div>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
