import { conferenceActions } from "./conference-slice";

export const GetConferenceByCode = (code) => {
  return async (dispatch) => {
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}registropago/conferences/getbyCode`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: "public@aba.org.do",
            ConferecenCodeName: code,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el congreso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data,
          })
        );

        localStorage.setItem(`image${code}`, response.data.imageUrl);
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConference({
            conference: {},
          })
        );
      }
    } catch (error) {}
  };
};

export const GetAllConferences = () => {
  return async (dispatch) => {
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: "public@aba.org.do",
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el congreso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data.filter(
              (conference) => conference.status === true
            ),
          })
        );

        // localStorage.setItem(`image${code}`, response.data.imageUrl);
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConference({
            conference: {},
          })
        );
      }
    } catch (error) {}
  };
};
