import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "primereact/card";
import { FileUpload } from "primereact/fileupload";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";

import {
  GetOrdensById,
  UploadFileOrder,
} from "../../../store/orden-store/orden-actions";
import { getTokenInfo } from "../../../utils/Guard";
import Loading from "../../../components/UI/Loading";
import "./OrdenTransfer.css";

const OrdenTransfer = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const orden = useSelector((state) => state.orden.orden);
  const notification = useSelector((state) => state.ui.notification);

  const userInfo = getTokenInfo();
  const [amountDollar, setAmountDollar] = useState(0);
  const [amountRD, setAmountRD] = useState(0);
  const [count, setCount] = useState(0);
  const [isloaded, setIsload] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (orden && (orden.id === undefined || orden.id === 0)) {
      dispatch(GetOrdensById(userInfo.OrdenId, userInfo.Email));
    }
  }, [dispatch, orden, userInfo.Email, userInfo.OrdenId]);

  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id > 0) {
      let counting = 0;
      orden.users.forEach((user) => {
        if (user.id !== 0) {
          counting++;
        }
      });
      setCount(counting);
    }
  }, [orden, count]);

  useEffect(() => {
    if (orden && orden.id !== undefined && orden.id !== 0) {
      if (
        orden.discount &&
        orden.discount.id !== undefined &&
        orden.discount.id !== 0 &&
        count > 0
      ) {
        let discount = 0;
        if (orden.discount.percentage) {
          discount =
            orden.conference.price *
            count *
            (orden.discount.discountPercentage / 100);
        }
        if (orden.discount.nominal) {
          discount = orden.discount.discountNominal * count;
        }
        const dollar = orden.conference.price * count;
        const rd = (dollar - discount) * orden.dollarRate;

        setAmountDollar(dollar - discount);
        setAmountRD(rd);
      } else {
        const dollar = orden.conference.price * count;
        const rd = dollar * orden.dollarRate;

        setAmountDollar(dollar);
        setAmountRD(rd);
      }
    }
  }, [orden, count]);

  useEffect(() => {
    if (isloaded && notification && notification.status === "success") {
      window.location.href = "/orden/transferenceRequested";
    }
  }, [isloaded, notification]);

  useEffect(() => {
    if (window.innerWidth <= 430 && !isMobile) {
      setIsMobile(true);
    }
  }, []);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const UploadHandler = ({ files, options }) => {
    const [file] = files;
    dispatch(UploadFileOrder(file, orden.id, userInfo.Email));
    setIsload(true);
  };

  const selectHandler = () => {
    setIsload(false);
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="align-items-center flex-wrap">
        <div className="align-items-center">
          {!isMobile && (
            <>
              <div style={{ textAlign: "left" }}>
                {!isloaded && (
                  <i
                    style={{ fontSize: "x-large" }}
                    className="pi pi-cloud-upload mr-3 text-warning"
                  ></i>
                )}
                {isloaded && (
                  <i
                    style={{ fontSize: "x-large" }}
                    className="pi pi-cloud-upload mr-3 text-success"
                  ></i>
                )}
                <span style={{ fontSize: "x-large", marginLeft: "1%" }}>
                  {file.name}
                </span>
                {!isloaded && (
                  <span
                    style={{
                      fontSize: "x-large",
                      padding: "0.5% 2%",
                      marginLeft: "2%",
                    }}
                    className="alert-solid-warning rounded-3 ml-5"
                  >
                    Pendiente
                  </span>
                )}
                {isloaded && (
                  <span
                    style={{
                      fontSize: "x-large",
                      padding: "0.5% 2%",
                      marginLeft: "2%",
                    }}
                    className="alert-solid-success rounded-3 ml-5"
                  >
                    Completado
                  </span>
                )}
              </div>
            </>
          )}
          {isMobile && (
            <>
              <div style={{ textAlign: "left" }}>
                {!isloaded && (
                  <i
                    style={{ fontSize: "x-large" }}
                    className="pi pi-cloud-upload mr-3 text-warning"
                  ></i>
                )}
                {isloaded && (
                  <i
                    style={{ fontSize: "x-large" }}
                    className="pi pi-cloud-upload mr-3 text-success"
                  ></i>
                )}
                <span style={{ fontSize: "x-large", marginLeft: "1%" }}>
                  {file.name}
                </span>
                {!isloaded && (
                  <span
                    style={{
                      fontSize: "x-large",
                      padding: "0.5% 2%",
                      marginLeft: "2%",
                    }}
                    className="alert-solid-warning rounded-3 ml-5"
                  >
                    Pendiente
                  </span>
                )}
                {isloaded && (
                  <span
                    style={{
                      fontSize: "x-large",
                      padding: "0.5% 2%",
                      marginLeft: "2%",
                    }}
                    className="alert-solid-success rounded-3 ml-5"
                  >
                    Completado
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="card flex justify-content-center ">
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <Card className="ordenTransferBox">
            {orden && (orden.id === undefined || orden.id === 0) && <Loading />}
            {orden && orden.id !== undefined && orden.id > 0 && (
              <div>

                {!isMobile && (
                  <>


                    <div className="forms">
                      <div className="ordenTransferTitle ">
                        Orden
                      </div>
                      <div className="column">
                        <label>
                          Número de órden:
                        </label>
                        <h1>
                          {orden.id}
                        </h1>
                      </div>
                      <div className="column">
                        <label>
                          Monto en dólares
                        </label>
                        <h1>
                          US{USDollar.format(amountDollar)}
                        </h1>
                      </div>
                      <div className="column">
                        <label>
                          Tasa
                        </label>
                        <h1>
                          RD{USDollar.format(orden.dollarRate)}
                        </h1>
                      </div>
                      <div className="column">
                        <label>
                          Monto en pesos
                        </label>
                        <h1>
                          RD{USDollar.format(amountRD)}
                        </h1>
                      </div>
                    </div>

                  </>
                )}
                {isMobile && (
                  <>
                    <div className="forms">
                      <div className="ordenTransferTitle ">
                        Orden
                      </div>
                      <div className="column">
                        <label>
                          Número de órden:
                        </label>
                        <h1>
                          {orden.id}
                        </h1>
                      </div>
                      <div className="column">
                        <label>
                          Monto en dólares
                        </label>
                        <h1>
                          US{USDollar.format(amountDollar)}
                        </h1>
                      </div>
                      <div className="column">
                        <label>
                          Tasa
                        </label>
                        <h1>
                          RD{USDollar.format(orden.dollarRate)}
                        </h1>
                      </div>
                      <div className="column">
                        <label>
                          Monto en pesos
                        </label>
                        <h1>
                          RD{USDollar.format(amountRD)}
                        </h1>
                      </div>
                    </div>

                  </>
                )}
                <div className="OrdenTransferMessage">
                  Comprobante de pago
                </div>
                <div className="field col-12 md:col-12  ">
                  <FileUpload
                    className="ordenTransferFileUpload"
                    name="fileUpload"
                    accept="*/*"
                    customUpload={true}
                    uploadHandler={UploadHandler}
                    onSelect={selectHandler}
                    mode="advanced"
                    uploadLabel={"Subir"}
                    chooseLabel="Seleccionar Archivo"
                    cancelLabel="Cancelar"
                    itemTemplate={itemTemplate}
                    emptyTemplate={
                      <div className="m-0">
                        Seleccione el archivo con el comprobante de pago.
                      </div>
                    }
                  />
                </div>
                <span
                  className="ordenTransferInfo"
                >
                  Para finalizar su registro solo tiene que seguir los
                  siguientes pasos:
                  <br />
                  <br />
                </span>
                <div>
                  <ol className="ordenTransferInfo">
                    <li>
                      "Realizar la transferencia a una de nuestras cuentas:"
                      <ul>
                        <li>
                          <strong>Banco BHD</strong>
                          <ul>
                            <li>
                              <strong>Cuenta Ahorro en dólares (US$):</strong>{" "}
                              00258930118.
                            </li>
                            <li>
                              <strong>Cuenta Pesos Dominicanos</strong>{" "}
                              00258930100
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <p className="ordenTransferInfo">
                    Banco Beneficiario: <strong>Banco BHD , S. A. </strong>
                    Swift: <strong>BCBHDOSDXXX </strong> , Favor incluir como
                    referencia del pago el número de orden asignado.
                  </p>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};
export default OrdenTransfer;
