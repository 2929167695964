import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from 'primereact/progressspinner';
import "primeicons/primeicons.css";
import "./Loading.css";

const Loading = (props) => {
  const [visible] = useState(props.visible);

  return (

      <div style={{textAlign:"center",width:"100%",alignContent:"center", height:"100%",backgroundColor:"#F8F9FC",position:"fixed",top:"0",left:"0",zIndex:"9999"}}>
         <ProgressSpinner className="loadingDialog" style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration="1s" />
      </div>
    );
};
export default Loading;
