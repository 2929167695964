import React from "react";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CleanUser, GetUserByemail } from "../store/user-store/user-actions";
import { userActions } from "../store/user-store/user-slice";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import UserFormDetail from "./Forms/UserFormDetail";
import { getTokenInfo } from "../utils/Guard";
import Loading from "./UI/Loading";
import "../components/Forms/UserFormDetail.css";

const DialogModal = (props) => {
  const userInfo = getTokenInfo();
  const [searchEmail, setSearchEmail] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      props.searchEmail !== undefined &&
      props.searchEmail !== "" &&
      searchEmail === ""
    ) {
      setSearchEmail(props.searchEmail);
      dispatch(GetUserByemail(props.searchEmail, userInfo.Email, []));
    }
  }, [dispatch, props.searchEmail, searchEmail, userInfo.Email]);

  const searchUserHandler = () => {
    dispatch(GetUserByemail(searchEmail, userInfo.Email, []));
  };
  const onSearchHandler = (id, value) => {
    setSearchEmail(value);
  };

  const cleanHandler = () => {
    dispatch(CleanUser());
    props.setVisible(false);
    props.setVisible(true);
    setSearchEmail("");
  };
  const cancelHanlder = () => {
    cleanHandler();
    dispatch(userActions.setFieldValid({ id: "name", value: true }));
    dispatch(userActions.setFieldValid({ id: "lastName", value: true }));
    dispatch(userActions.setFieldValid({ id: "email", value: true }));
    dispatch(userActions.setFieldValid({ id: "cellPhone", value: true }));
    dispatch(userActions.setFieldValid({ id: "country", value: true }));
    props.setVisible(false);
  };

  const footerContent = (
    <>
      {props.refresh === false && (
        <div style={{ marginTop: "5px" }}>
          <Button
            text
            raised
            type="button"
            label="Guardar"
            icon="pi pi-check"
            onClick={() => {
              props.addUserHandler();
            }}
            className="DialogModalSaveButton"
          />
          <Button
            text
            raised
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => cancelHanlder()}
            className="DialogModalCancelButton"
          />
        </div>
      )}
    </>
  );
 
  

  const content = (
    <>
      {props.refresh === false && (
        <div className="p-inputgroup">
          <InputText
            className="DialogModalSearchInput"
            placeholder="example@email.com"
            value={searchEmail}
            onChange={(e) => onSearchHandler("searchEmail", e.target.value)}
          />
          <Button
            rounded
            icon="pi pi-search"
            className="DialogModalSearchButton"
            style={{ marginLeft: "2px", marginRight: "2px" }}
            tooltip="Buscar"
            onClick={() => searchUserHandler()}
          />
          <Button
            rounded
            className="DialogModalClearButton"
            icon="pi pi-filter-slash"
            aria-label="Filter"
            tooltip="Limpiar"
            onClick={() => cleanHandler()}
          />
        </div>
      )}
      {props.refresh === true && (
        <>
          <Loading />
        </>
      )}
      {props.refresh === false && <UserFormDetail isCreation={true} />}
    </>
  );

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={props.header}
        visible={props.visible}
        onHide={() => props.setVisible(false)}
        footer={footerContent}
        position="center"
        className="DialogModalSize"
      >
        {content}
      </Dialog>
    </div>
  );
};
export default DialogModal;
