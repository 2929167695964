import React from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Notification from "../components/UI/Notification";
import { GetConferenceByCode } from "../store/conferences-store/conference-actions";
import NavBarMenu from "./NavBarMenu";
import { validateImage, validateToken } from "../utils/Guard";

const BaseLayout = () => {
  const notification = useSelector((state) => state.ui.notification);
  const imageUrl = useSelector((state) => state.conference.ImageUrl);
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  const isValid = validateToken(params.conferenceCode, location.pathname);

  useEffect(() => {
    if (
      !location.pathname.includes("home") &&
      !location.pathname.includes("orden") &&
      !location.pathname.includes("conferences") &&
      !location.pathname.includes("login") &&
      isValid
    ) {
      window.location.href = "/conferences";
    }
  }, [isValid, location.pathname, params.conferenceCode]);


  useEffect(() => {
    if ( params.conferenceCode && !validateImage(params.conferenceCode)) {
      dispatch(GetConferenceByCode(params.conferenceCode));
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  }, [dispatch, imageUrl, params.conferenceCode]);



  return (
    <>
      {notification && (
        <Notification
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )}
      <div>{!isValid && <Outlet />}</div>
      <div>
        {isValid && (
          <>
            <NavBarMenu />
            <Outlet />
          </>
        )}
      </div>
    </>
  );
};
export default BaseLayout;
