import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companies: [],
  
};

const companiesSlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanies(state, actions) {
      state.companies = actions.payload.companies;
    },
  },
});

export const companyActions = companiesSlice.actions;
export default companiesSlice;
